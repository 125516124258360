.HealCalculator{
    margin:auto;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 1px 1px #eeeeee;
    display:flex;
    flex-direction: column;
    flex:0.3;
}

/*input[type="number"] {*/
/*    width:50px;*/
/*    -moz-appearance: textfield;*/
/*}*/

/*input[type="number"]::-webkit-outer-spin-button,*/
/*input[type="number"]::-webkit-inner-spin-button {*/
/*    -webkit-appearance: none;*/
/*    width:50px;*/
/*    margin: 0;*/
/*}*/

input[type="number"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    width: 5px;
    height: 30px;
}
