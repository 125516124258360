.MessageBoxContainer{
    margin-top: 15px;
    padding:15px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 1px 1px #eeeeee;
}

.MessageForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.InputGroup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
}
