.PostCard {
    text-align: center;
    padding: 50px;
}

.PostCardBody {
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 1px 1px #eeeeee;
    padding: 40px;

}

.Date{
    bottom: 10px;
    right:10px;
    color: black;
}

mark {
    color: darkslateblue;
    background-color: transparent;
}

h1 {
    color: darkslategrey;
    text-align: center;
}

h2 {
    color: darkslategray;
    text-align: left;
}

p {
    text-align: left;
    padding: 15px;
}

img {
    max-height: 400px;
    max-width: 400px;
    min-width:100px;
    display: block;
    width:100%;
    height:auto;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    /*margin-top: 20px;*/
    /*margin-bottom: 20px;*/
    border-radius: 5px;
    border: 2px solid #000;
    margin: 20px auto 20px auto;
}

li{
    text-align:left;
}